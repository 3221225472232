body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: #000;
}

@font-face {
  font-family: Aeonik;
  src:
    url("fonts/Aeonik-Regular.woff2") format("woff2"),
    url("fonts/Aeonik-Regular.woff") format("woff");
}

button.slick-arrow.slick-prev::before,
button.slick-arrow.slick-next::before {
  color: #ccc;
}
